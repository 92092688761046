<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">内容管理</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-table class="main-table" :pagination="{
			current: query.page,
			total: pagetotal,
			showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
		}" @change="listChange" :columns="columns" :data-source="data" rowKey="equipment_id" style="margin-top: 14px"
			v-viewer>
			<span slot="action" slot-scope="text, record">
				<a-space>
					<a @click="toEdit(record)">编辑</a>
				</a-space>
			</span>
		</a-table>
	</div>
</template>

<script>
import { domain } from '@/siteInfo.js'

export default {
	data() {
		return {
			query: {
				page: 1,
				limit: 10
			},
			customer_id: null,
			pagetotal: 0,
			checkValue: [],
			packageList: [],
			columns: [
				{
					title: '类型',
					dataIndex: 'name',
				},
				{
					title: '标题',
					dataIndex: 'value',
				},
				{
					title: '修改时间',
					dataIndex: 'updated_at_str'
				},
				{
					title: '操作',
					scopedSlots: {
						customRender: 'action',
					},
				},
			],
			data: [],
			levelList: [],
			selectedRowKeys: []
		}
	},
	created() {
		this.getList()
	},
	methods: {
		toEdit(record) {
			this.$router.push({
				path: '/info',
				query: {
					key: record.key
				}
			})
		},
		getList() {
			this.$post(domain + '/admin/v1/config/list', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList()
		},
	}
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4e80f8;
	}

	.title {
		color: #383f50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
